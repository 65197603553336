import "./App.css";
import DigitalClock from "./DigitalClock";
import MailchimpComponent from "./MailchimpComponent";

function App() {
  return (
    <div className="App">
      <div className="Header">
        <div className="Logo">
          <img className="FullLogo" src="full-logo.png" alt="Never Forever" />
          <img
            className="PartialLogo"
            src="partial-logo.png"
            alt="Never Forever"
          />
          <DigitalClock />
        </div>
      </div>
      <div className="floating-text">ARRIVING SOON</div>
      <img
        className="Image"
        src="/img-2.png"
        alt="Girl wearing anime top blurred"
      />
      <img className="Image" src="/img-1.png" alt="Girl wearing anime top" />
      <MailchimpComponent />
    </div>
  );
}

export default App;
