import React, { useState, useEffect } from "react";

function MailchimpComponent() {
  const [isPopupVisible, setPopupVisibility] = useState(true);
  const [slideUp, setSlideUp] = useState(false);

  useEffect(() => {
    // Slide up after 2 seconds of component mounting
    const slideUpTimer = setTimeout(() => {
      setSlideUp(true);
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearTimeout(slideUpTimer);
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      // Load the external script
      const script = document.createElement("script");
      script.src =
        "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
      script.async = true;
      script.onerror = (errorEvent) => {
        console.error("Error loading the script:", errorEvent);
      };
      script.onload = () => {
        // Call the inline script once the external script loads
        executeInlineScript();
      };

      document.body.appendChild(script);

      // Clean up on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isPopupVisible]);

  const executeInlineScript = () => {
    window.fnames = ["EMAIL", "FNAME", "LNAME", "ADDRESS", "PHONE", "BIRTHDAY"];
    window.ftypes = ["email", "text", "text", "address", "phone", "birthday"];
  };

  const handleClosePopup = () => {
    setPopupVisibility(false);
  };

  return (
    isPopupVisible && (
      <div
        className={`Popup ${slideUp ? "slide-up" : ""}`}
        id="mc_embed_signup"
      >
        <button className="CloseButton" onClick={handleClosePopup}>
          <div className="close-icon"></div>
        </button>
        <div id="mc_embed_signup">
          <form
            action="https://gmail.us21.list-manage.com/subscribe/post?u=a1328e745c99784fdbdbc0f3e&amp;id=e654dd9585&amp;f_id=005561e1f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <span className="NewsletterTitle">Join our world.</span>
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  placeholder="your email"
                  required
                  defaultValue=""
                />
                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
              </div>
              <div id="mce-responses" className="clear foot">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input
                  type="text"
                  name="b_a1328e745c99784fdbdbc0f3e_e654dd9585"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                    value="enter"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default MailchimpComponent;
